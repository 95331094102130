function Footer(props) {
  const getProcessBtnID = () => {
    props.onProcessClick();
  };

  const getResetBtnID = () => {
    props.onResetClick();
  };

  return (
    <div className="row">
      <div className="col-8">
        <button
          className="btn btn-primary ml-3"
          id="process"
          disabled={props.load}
          onClick={getProcessBtnID}
          style={{ cursor: props.cursor }}
        >
          Process
        </button>
        <button
          className="btn btn-outline-primary ml-3"
          id="reset"
          onClick={getResetBtnID}
        >
          Reset
        </button>
      </div>
      <div className="col-4">
        <p className="text-right"> Computing time: {props.duration}s</p>
      </div>
    </div>
  );
}

export default Footer;
