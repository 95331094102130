import ModelSelection from "../Components/SettingsSecComponents/ModelSelection";
import Configurations from "../Components/SettingsSecComponents/Configurations";

function QaSettingSec(props) {
  const modelSelectHandler = (model) => {
    console.log("model:", model);
    props.onModel(model);
  };

  const tempSetHandler = (temp) => {
    props.onTemp(temp);
  };

  const maxlenSetHandler = (maxlen) => {
    props.onMaxlen(maxlen);
  };

  return (
    <div className="col-3">
      <ModelSelection onModelSelect={modelSelectHandler} />
      <Configurations
        onTempChange={tempSetHandler}
        onMaxlenChange={maxlenSetHandler}
        temp={props.temp}
        maxlen={props.maxlen}
      />
      <div>
        <p>
          Important:{" "}
          <small>
            If the answer doesn't make sense, please increase the max length and
            try again.
          </small>
        </p>
      </div>
    </div>
  );
}

export default QaSettingSec;
