import { useState } from "react";

function PredefinedTemplates(props) {
  const [template, setTemplate] = useState("text_generation");

  const selectTemplateHandler = (e) => {
    setTemplate(e.target.value);
    props.onTemplateSelect(e.target.value);
  };
  return (
    <div className="mb-3">
      <h6>Predefined templates</h6>
      <select
        className="form-control w-100"
        name="templates"
        id="templates"
        value={template}
        onChange={selectTemplateHandler}
      >
        <option value="text_generation">Text Generation</option>
        <option value="question_generation">Question Generation</option>
        <option value="question_answering">Question Answering</option>
        <option value="intent_generation">Intent Generation</option>
        <option value="utterance_generation">Utterance Generation</option>
        <option value="sentiment_analysis">Sentiment Analysis</option>
        <option value="machine_translation">Machine Translation</option>
        <option value="paraphrasing">Paraphrasing</option>
        <option value="spelling_correction">Spelling Correction</option>
        <option value="summarization">Summarization</option>
      </select>
    </div>
  );
}

export default PredefinedTemplates;
