function ResultField(props) {
  return (
    <div className="row mt-3">
      <div className="col-12">
        <textarea
          className="form-control border border-secondary"
          rows="10"
          readOnly
          value={props.generatedTxt}
        />
        <br></br>
      </div>
    </div>
  );
}

export default ResultField;
