import ModelItem from "./ModelItem";

function ModelSelection(props) {
  const selectedModelHandler = (e) => {
    props.onModelSelect(e.target.id);
  };

  return (
    <div className="mb-3">
      <h6>Model</h6>
      <div
        className="p-sm-2 border border-secondary rounded mb-1"
        onChange={selectedModelHandler}
      >
        <ModelItem id="Falcon-7B-instruct" model="Falcon-7B-instruct" checkStatus="checked" />
        <ModelItem id="Falcon-40B-instruct" model="Falcon-40B-instruct" />
      </div>
    </div>
  );
}

export default ModelSelection;
