function QuestionField(props) {
  return (
    <div className="form-group mt-3">
      <h6>Question</h6>
      <div className="row">
        <div className="col-11">
          <input
            type="text"
            className="form-control"
            id="question"
            placeholder="Enter a question here"
            value={props.question}
            onChange={(e) => props.onQuestion(e.target.value)}
          />
        </div>
        <div className="col-1 d-flex flex-row-reverse">
          <button
            className="btn btn-outline-dark py-0"
            id="clear"
            onClick={() => {
              props.onQuestion("");
            }}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}

export default QuestionField;
