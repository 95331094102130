function InlineRadioItem(props) {
  const { id, display, checkStatus } = props;

  return (
    <div className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="radio"
        name="flexInlineRadioDefault"
        id={id}
        defaultChecked={checkStatus}
      />
      <label className="form-check-label" htmlFor={id}>
        {display}
      </label>
    </div>
  );
}

export default InlineRadioItem;
