import ConfigItem from "./ConfigItem";
import InlineRadioItem from "./InlineRadioItem";

function Configurations(props) {
  const getTempHandler = (value) => {
    props.onTempChange(value);
  };

  const getMaxlenHandler = (value) => {
    props.onMaxlenChange(value);
  };

  const getMinlenHandler = (value) => {
    props.onMinlenChange(value);
  };

  const getTopkHandler = (value) => {
    props.onTopkChange(value);
  };

  const getRepetitionPenaltyHandler = (value) => {
    props.onRepetitionChange(value);
  };

  const selectedDoSampleHandler = (e) => {
    props.onDoSampleSelect(e.target.id);
  };

  return (
    <div>
      <h6>Configurations</h6>
      <div className="p-sm-2 border border-secondary rounded">
        <ConfigItem
          param="Temperature"
          description="It controls the randomness of the generated text. A value of 0 makes the model deterministic, which means that it will always generate the same output for a given input text. A value of 1 makes the model take the most risks and use a lot of creativity."
          maxValue="1"
          interval="0.01"
          onGetValue={getTempHandler}
          defaultValue={props.temp}
          xyz={props.temp}
        />
        <ConfigItem
          param="Min Length"
          description="The minimum number of tokens (words, punctuations,symbols, etc) to be generated."
          maxValue="50"
          interval="1"
          onGetValue={getMinlenHandler}
          defaultValue={props.minlen}
          xyz={props.minlen}
        />
        <ConfigItem
          param="Max Length"
          description="The maximum number of tokens (words, punctuations,symbols, etc) to be generated."
          maxValue="512"
          interval="1"
          onGetValue={getMaxlenHandler}
          defaultValue={props.maxlen}
          xyz={props.maxlen}
        />
        <ConfigItem
          param="Top-k"
          description="The number of highest probability vocabulary tokens to keep for top-k-filtering. Default: 50."
          maxValue="50"
          interval="1"
          onGetValue={getTopkHandler}
          defaultValue={props.top_k}
          xyz={props.top_k}
        />
        <ConfigItem
          param="Repetition penalty"
          description="It's used to penalise words that were already generated or belong to the context. 1.0 means no penalty. Default: 1.0."
          maxValue="1"
          interval="0.01"
          onGetValue={getRepetitionPenaltyHandler}
          defaultValue={props.repetition_penalty}
          xyz={props.repetition_penalty}
        />
        <div className="mb-2">
          <div className="form-row">
            <div className="col-5">
              <span>
                Do Sample
                <sup>
                  <i
                    title="Whether or not to use sampling; use greedy decoding otherwise. Default: True."
                    className="far fa-question-circle"
                  ></i>
                </sup>
                :
              </span>
            </div>
            <div className="col-6" onChange={selectedDoSampleHandler}>
              <InlineRadioItem id="true" display="True" checkStatus="checked" />
              <InlineRadioItem id="false" display="False" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Configurations;
