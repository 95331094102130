import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import UiHeader from "./Components/UiHeader";
import UiBody from "./Components/UiBody";
import Login from "./Authentication/Login";
import QaBody from "./DocumentQA/QaBody";

function App() {
  const [login, setLogin] = useState(false);
  const [token, setToken] = useState("");

  return (
    <div className="d-flex flex-column">
      {!login && <Login setLogin={setLogin} setToken={setToken} />}
      {/* {login && (
        <div>
          <UiHeader  />
          <UiBody token={token} />
        </div>
      )}
      {login && (
        <div>
          <QaHeader  />
          <QaBody token={token} />
        </div>
      )} */}
      {login && (
        <div>
          <Router>
            <UiHeader />

            <Routes>
              <Route exact path="/" element={<UiBody token={token} />} />
              <Route path="documentqa" element={<QaBody token={token} />} />
            </Routes>
          </Router>
        </div>
      )}
    </div>
  );
}

export default App;
