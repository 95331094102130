function DocumentField(props) {
  return (
    <div className="col-9">
      <div className="d-flex flex-column h-100">
        <h6>Document</h6>
        <div className="flex-grow-1">
          <textarea
            rows="15"
            className="form-control border border-secondary h-100"
            placeholder="Paste your document here"
            value={props.inputTxt}
            onChange={(e) => props.oninputText(e.target.value)}
          ></textarea>
        </div>
      </div>
    </div>
  );
}

export default DocumentField;
