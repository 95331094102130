import { useState } from "react";
import "./Login.css";
import axios from "axios";

const urlAuth ="https://spiahimdmc.execute-api.ap-southeast-2.amazonaws.com/dev/login";

function Login({ setLogin, setToken }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = () => {
    const data = {
      id: username,
      password: password,
    };

    // Check user info
    if (username && password) {
      axios
        .post(urlAuth, {
          data,
        })
        .then((response) => {
          const { token } = response.data;
          if (token) {
            setErrorMessage("");
            setLogin(true);
            setToken(token);
          }
        })
        .catch(() => {
          setErrorMessage("Please provide correct username and password.");
        });
    } else {
      setErrorMessage("Please provide correct username and password.");
    }
  };

  // Login form
  const renderForm = (
    <div className="form">
      <div>
        <div className="input-container">
          <label>Username </label>
          <input
            type="text"
            name="uname"
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="input-container">
          <label>Password </label>
          <input
            type="password"
            name="pwd"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {errorMessage !== "" && <div className="error">{errorMessage}</div>}
        <div className="button-container">
          <input type="submit" onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="app">
      <div className="login-form">
        <div className="title">Sign In</div>
        {renderForm}
      </div>
    </div>
  );
}

export default Login;
