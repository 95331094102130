import { useEffect, useState } from "react";

function ConfigItem(props) {
  // const { param, description, maxValue, interval, onGetValue, defaultValue } =
  //   props;

  const [value, setValue] = useState(props.defaultValue);
  useEffect(() => {
    setValue(props.defaultValue);
  });

  const getValueHandler = (e) => {
    setValue(e.target.value);
    props.onGetValue(e.target.value);
  };

  return (
    <div className="mb-2">
      <form>
        <span>
          {props.param}
          <sup>
            <i title={props.description} className="far fa-question-circle"></i>
          </sup>
          : <input type="text" value={value} readOnly className="configField" />
        </span>
        <input
          className="formrange"
          type="range"
          min="0"
          max={props.maxValue}
          step={props.interval}
          name={props.param}
          id={props.param}
          onChange={getValueHandler}
          value={props.xyz}
        />
      </form>
    </div>
  );
}

export default ConfigItem;
