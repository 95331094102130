function ContentField(props) {
  const typedTextHandler = (e) => {
    // setInputText(e.target.value);
    props.oninputText(e.target.value);
  };

  return (
    <div className="col-8">
      <div className="d-flex flex-column h-100">
        <h6>Content</h6>
        <div className="flex-grow-1">
          <textarea
            className="form-control border border-secondary h-100"
            value={props.inputTxt}
            onChange={(e) => typedTextHandler(e)}
          ></textarea>
        </div>
      </div>
    </div>
  );
}

export default ContentField;
