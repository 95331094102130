import { Link } from "react-router-dom";

function UiHeader(props) {
  return (
    <div>
      <div className="jumbotron mb-3 header jumbotron-fluid">
        <div className="container header">
          <div className="row">
            <div className="col-8 d-flex flex-column">
              <h4>Gen3 Playground</h4>
              <p className="text-warning">Monday to Friday 7am - 12am NZST</p>
            </div>
            <div className="col-3 d-flex flex-row-reverse">
              <div>
                <ul>
                  <li>
                    <Link className="link" to="/">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/documentqa">
                      Document Q&A
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-1 d-flex flex-row-reverse">
              <img
                src="https://6207230.fs1.hubspotusercontent-na1.net/hub/6207230/hubfs/AMB%20Lock%20Up%20Orange%20RGB_White-Small.png?width=75&amp;name=AMB%20Lock%20Up%20Orange%20RGB_White-Small.png"
                srcSet="https://6207230.fs1.hubspotusercontent-na1.net/hub/6207230/hubfs/AMB%20Lock%20Up%20Orange%20RGB_White-Small.png?width=75&amp;name=AMB%20Lock%20Up%20Orange%20RGB_White-Small.png 1x, https://6207230.fs1.hubspotusercontent-na1.net/hub/6207230/hubfs/AMB%20Lock%20Up%20Orange%20RGB_White-Small.png?width=150&amp;name=AMB%20Lock%20Up%20Orange%20RGB_White-Small.png 2x"
                alt="AMB Lock Up Orange RGB_White-Small"
                width="75px"
                height="50px"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UiHeader;
