import { useState } from "react";
import DocumentField from "./DocumentField";
import QuestionField from "./QuestionField";
import AnswerField from "./AnswerField";
import Footer from "../Components/Footer";
import QaSettingSec from "./QaSettingSec";
import axios from "axios";

const prefixSamples =
  "Context: NLP Cloud was founded in 2021 when the team realized there was no easy way to reliably leverage Natural Language Processing in production.\nQuestion: When was NLP Cloud founded?\nAnswer: NLP Cloud founded in 2021.\n###\nContext: All plans can be stopped anytime. You only pay for the time you used the service. In case of a downgrade, you will get a discount on your next invoice..\nQuestion: When can plans be stopped?\nAnswer: Plans can be cancelled at anytime.\n###\n";

function QaBody(props) {
  const [documentTxt, setDocumentTxt] = useState("");
  const [question, setQuestion] = useState("");
  const [generatedAnswer, setGeneratedAnswer] = useState("");
  const [model, setModel] = useState("gpt_j_6B");
  const [temp, setTemp] = useState("0.10");
  const [maxlen, setMaxlen] = useState("30");
  const [duration, setDuration] = useState("0.000");
  const [load, setLoad] = useState(false);
  const [cursor, setCursor] = useState("pointer");

  // Process button
  const processHandler = () => {
    setLoad(true);
    setCursor("wait");
    const urlModel =
      "https://spiahimdmc.execute-api.ap-southeast-2.amazonaws.com/dev";

    // data, params and model info
    const contextAndQuestion =
      prefixSamples +
      "Context: " +
      documentTxt.trim() +
      "\n" +
      "Question: " +
      question +
      "\n" +
      "Answer:";

    const inputLen = contextAndQuestion.split(" ").length;
    console.log("inputLen:\n", inputLen);

    const inputTextArray = contextAndQuestion.split(/\s*\b\s*/);
    const inputTextLength = inputTextArray.length;

    const data = {
      data: {
        inputs: contextAndQuestion,
        parameters: {
          max_new_tokens: parseInt(maxlen),
          temperature: parseFloat("0.01"), // parseFloat(temp),
          top_p: parseInt("0.2"),
          return_full_text: true,
        },
      },
      model: model,
    };

    // JWT token
    const config = {
      headers: { Authorization: `Bearer ${props.token}` },
    };

    // Request interceptor will set startTime
    axios.interceptors.request.use(
      function (config) {
        config.metadata = { startTime: new Date() };
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    // Response interceptor will set endTime & calculate the duration
    axios.interceptors.response.use(
      function (response) {
        response.config.metadata.endTime = new Date();
        response.duration =
          (response.config.metadata.endTime -
            response.config.metadata.startTime) /
          1000; // convert ms to s
        setDuration(response.duration);
        return response;
      },
      function (error) {
        error.config.metadata.endTime = new Date();
        error.duration =
          error.config.metadata.endTime - error.config.metadata.startTime;
        return Promise.reject(error);
      }
    );

    axios
      .post(urlModel, data, config)
      .then((res) => {
        const generated_text = res.data[0].generated_text;
        const len = data.data.inputs.length;
        const lastSymbolIdx = generated_text.lastIndexOf("\nAnswer: ");
        // console.log("inputs: ", generated_text);
        // console.log("lastSymbolIdx:", lastSymbolIdx);

        const idx = generated_text.substring(len).indexOf("\n###");
        // console.log("idx:", idx);
        // console.log(
        //   "generated_text.substring(len):",
        //   generated_text.substring(len)
        // );

        let trimmed_text = "";
        if (idx !== -1) {
          if (lastSymbolIdx !== -1) {
            trimmed_text = trimmed_text = generated_text.substring(
              lastSymbolIdx + 9,
              len + idx
            );
          } else {
            trimmed_text = generated_text.substring(0, len + idx);
          }
          // console.log("trimmed_text:", trimmed_text);
          setGeneratedAnswer(trimmed_text);
        } else {
          if (lastSymbolIdx !== -1) {
            setGeneratedAnswer(generated_text.substring(lastSymbolIdx + 9));
          } else {
            setGeneratedAnswer(generated_text.substring(0));
          }
        }
        setLoad(false);
        setCursor("pointer");
      })
      .catch((e) => {
        setLoad(false);
        setCursor("pointer");
        if (model === "MPT-7B-instruct") {
          setGeneratedAnswer(
            "This model is not up and running yet. Please choose another one 🙂"
          );
        } else if (inputLen > 1200) {
          setGeneratedAnswer(
            "Oops, it seems your document is too long. Shorten it may help."
          );
        } else {
          setGeneratedAnswer(
            "Hi there! Please note that this playground currently runs on request. Feel free to flick Shuxiang a message if you want to do some testing!"
          );
        }
      });
  };

  // Reset button
  const resetHandler = () => {
    setGeneratedAnswer("");
    setDuration("0.000");
  };

  return (
    <div className="container h-100">
      <div className="d-flex justify-content-center">
        <h5>Document Q&A</h5>
      </div>
      <div className="row">
        <DocumentField inputTxt={documentTxt} oninputText={setDocumentTxt} />
        <QaSettingSec
          temp={temp}
          maxlen={maxlen}
          onModel={setModel}
          onTemp={setTemp}
          onMaxlen={setMaxlen}
        />
      </div>
      <QuestionField question={question} onQuestion={setQuestion} />
      <AnswerField
        generatedAnswer={generatedAnswer}
        model={model}
        temp={temp}
        maxlen={maxlen}
      />
      <Footer
        load={load}
        cursor={cursor}
        onProcessClick={processHandler}
        onResetClick={resetHandler}
        duration={duration}
      />
    </div>
  );
}

export default QaBody;
