import { useState } from "react";
import ContentField from "./ContentField";
import ResultField from "./ResultField";
import Footer from "./Footer";
import SettingsSection from "./SettingsSection";
import axios from "axios";

import templates from "../Templates/predefined_templates.json";

function UiBody({ token }) {
  const [inputTxt, setInputTxt] = useState(templates["text_generation"]);
  const [generatedTxt, setGeneratedTxt] = useState("");
  const [model, setModel] = useState("Falcon-7B-instruct");
  const [temp, setTemp] = useState("0.75");
  const [minlen, setMinlen] = useState("1");
  const [maxlen, setMaxlen] = useState("100");
  const [top_k, setTopk] = useState("50");
  const [repetition_penalty, setRepetitionPenalty] = useState("1.0");
  const [do_sample, setDoSample] = useState(true);
  const [duration, setDuration] = useState("0.000");
  const [load, setLoad] = useState(false);
  const [cursor, setCursor] = useState("pointer");

  // Content input
  const inputTextHandler = (text) => {
    setInputTxt(text);
  };

  // Selected model
  const modelHandler = (model) => {
    setModel(model);
  };

  // Selected template
  const templateHandler = (template) => {
    setInputTxt(templates[template]);

    if (template === "text_generation") {
      setTemp("0.70");
      setMaxlen("120");
    } else if (template === "sentiment_analysis") {
      setTemp("0.80");
      setMaxlen("1");
    } else if (template === "intent_generation") {
      setTemp("0.70");
      setMaxlen("25");
    } else if (template === "utterance_generation") {
      setMaxlen("100");
    } else if (template === "question_generation") {
      setTemp("0.80");
      setMaxlen("100");
    } else if (template === "machine_translation") {
      setTemp("0.50");
      setMaxlen("50");
    } else if (template === "paraphrasing") {
      setTemp("0.55");
      setMaxlen("200");
    } else if (template === "spelling_correction") {
      setTemp("0.20");
      setMaxlen("75");
    } else if (template === "summarization") {
      setMaxlen("100");
    }
  };

  // Parameters
  const tempHandler = (temp) => {
    setTemp(temp);
  };

  const minlenHandler = (minlen) => {
    setMinlen(minlen);
  };

  const maxlenHandler = (maxlen) => {
    setMaxlen(maxlen);
  };

  const topkHandler = (top_k) => {
    setTopk(top_k);
  };

  const repetitionPenaltyHandler = (repetition_penalty) => {
    setRepetitionPenalty(repetition_penalty);
  };

  const doSampleHandler = (do_sample) => {
    setDoSample(do_sample);
  };

  const modelMatchingUrl ={
    "Falcon-40B-instruct":"https://lrwngdyf5a.execute-api.us-east-1.amazonaws.com/dev/falcon40b",
    "Falcon-7B-instruct":"https://xe6w7lndfb.execute-api.us-east-1.amazonaws.com/dev/falcon-api",
  }
  // Process button and switch endpoints
  const processHandler = () => {
    setLoad(true);
    setCursor("wait");
    const urlModel = modelMatchingUrl[model];
    console.log("urlModel",urlModel)

    // data, params and model info
    console.log("temperature: ", temp);
    console.log("minlen: ", minlen);
    console.log("maxlen: ", maxlen);
    console.log("top_k: ", top_k);
    console.log("repetition_penalty: ", repetition_penalty);
    console.log("do_sample: ", do_sample === "true");
    const data = {
      data: {
        inputs: inputTxt,
        parameters: {
          temperature: parseFloat(temp), // convert a string to a float number
          min_length: parseInt(minlen),
          max_new_tokens: parseInt(maxlen),
          top_k: parseInt(top_k),
          repetition_penalty: parseFloat(repetition_penalty),
          do_sample: do_sample === "true",
        },
      },
      model: model,
    };

    // JWT token
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    // Request interceptor will set startTime
    axios.interceptors.request.use(
      function (config) {
        config.metadata = { startTime: new Date() };
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    // Response interceptor will set endTime & calculate the duration
    axios.interceptors.response.use(
      function (response) {
        response.config.metadata.endTime = new Date();
        response.duration =
          (response.config.metadata.endTime -
            response.config.metadata.startTime) /
          1000; // convert ms to s
        setDuration(response.duration);
        return response;
      },
      function (error) {
        error.config.metadata.endTime = new Date();
        error.duration =
          error.config.metadata.endTime - error.config.metadata.startTime;
        return Promise.reject(error);
      }
    );

    axios
      .post(urlModel, data, config)
      .then((res) => {
        // setGeneratedTxt(res.data[0].generated_text);
        const generated_text = res.data[0].generated_text;
        const len = data.data.inputs.length;
        const lastSymbolIdx = data.data.inputs.lastIndexOf("###");
        // console.log("lastSymbolIdx:", lastSymbolIdx);
        const idx = generated_text.substring(len).indexOf("\n#");
        // console.log("idx:", idx);
        let trimmed_text = "";
        if (idx !== -1) {
          if (lastSymbolIdx !== -1) {
            trimmed_text = trimmed_text = generated_text.substring(
              lastSymbolIdx + 4,
              len + idx
            );
          } else {
            trimmed_text = generated_text.substring(0, len + idx);
          }
          // console.log("trimmed_text:", trimmed_text);
          setGeneratedTxt(trimmed_text);
        } else {
          if (lastSymbolIdx !== -1) {
            setGeneratedTxt(generated_text.substring(lastSymbolIdx + 4));
          } else {
            setGeneratedTxt(generated_text.substring(0));
          }
        }
        setLoad(false);
        setCursor("pointer");
      })
      .catch((e) => {
        setLoad(false);
        setCursor("pointer");
        if (
          model === "MPT-7B-instruct"
        ) {
          setGeneratedTxt(
            "This model is not up and running. Please choose the model Falcon-7B-instruct. 🙂"
          );
        } else {
          setGeneratedTxt(
            "Hi there! Please note that this playground currently runs on request. Feel free to flick Shuxiang a message if you want to do some testing!"
          );
        }
      });
  };

  // Reset button
  const resetHandler = () => {
    setGeneratedTxt("");
    setDuration("0.000");
  };

  return (
    <div className="container h-100">
      <div className="row">
        <ContentField inputTxt={inputTxt} oninputText={inputTextHandler} />
        <SettingsSection
          temp={temp}
          minlen={minlen}
          maxlen={maxlen}
          top_k={top_k}
          repetition_penalty={repetition_penalty}
          do_sample={do_sample}
          onModel={modelHandler}
          onTemplate={templateHandler}
          onTemp={tempHandler}
          onMinlen={minlenHandler}
          onMaxlen={maxlenHandler}
          onTopk={topkHandler}
          onRepetitionPenalty={repetitionPenaltyHandler}
          onDoSample={doSampleHandler}
        />
      </div>
      <ResultField
        generatedTxt={generatedTxt}
        model={model}
        temp={temp}
        maxlen={maxlen}
      />
      <Footer
        load={load}
        cursor={cursor}
        onProcessClick={processHandler}
        onResetClick={resetHandler}
        duration={duration}
      />
    </div>
  );
}

export default UiBody;
