import ModelSelection from "./SettingsSecComponents/ModelSelection";
import PredefinedTemplates from "./SettingsSecComponents/PredefinedTemplates";
import Configurations from "./SettingsSecComponents/Configurations";

function SettingsSection(props) {
  const modelSelectHandler = (model) => {
    // console.log("model:", model);
    props.onModel(model);
  };

  const templateSelectHandler = (template) => {
    props.onTemplate(template);
  };

  const tempSetHandler = (temp) => {
    props.onTemp(temp);
  };

  const minlenSetHandler = (minlen) => {
    props.onMinlen(minlen);
  };

  const maxlenSetHandler = (maxlen) => {
    props.onMaxlen(maxlen);
  };

  const topkSetHandler = (top_k) => {
    props.onTopk(top_k);
  };

  const repetitionSetHandler = (repetition_penalty) => {
    props.onRepetitionPenalty(repetition_penalty);
  };

  const doSampleSetHandler = (do_sample) => {
    props.onDoSample(do_sample);
  };

  return (
    <div className="col-4">
      <ModelSelection onModelSelect={modelSelectHandler} />
      <PredefinedTemplates onTemplateSelect={templateSelectHandler} />
      <Configurations
        temp={props.temp}
        minlen={props.minlen}
        maxlen={props.maxlen}
        top_k={props.top_k}
        repetition_penalty={props.repetition_penalty}
        do_sample={props.do_sample}
        onTempChange={tempSetHandler}
        onMinlenChange={minlenSetHandler}
        onMaxlenChange={maxlenSetHandler}
        onTopkChange={topkSetHandler}
        onRepetitionChange={repetitionSetHandler}
        onDoSampleSelect={doSampleSetHandler}
      />
    </div>
  );
}

export default SettingsSection;
