function AnswerField(props) {
  return (
    <div className="form-group mt-3">
      <h6>Answer</h6>
      <textarea
        className="form-control border border-secondary"
        rows="6"
        readOnly
        value={props.generatedAnswer}
      />
    </div>
  );
}

export default AnswerField;
